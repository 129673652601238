/*
 * @version: 1.0
 * @Date: 2023-07-03 15:00:49
 * @LastEditors: 杨慢慢
 */
import TextInput from './config/TextInputConfig.vue'
import TextareaInput from './config/TextareaInputConfig.vue'
import NumberInput from './config/NumberInputConfig.vue'
import AmountInput from './config/AmountInputConfig.vue'
import SelectInput from './config/SelectInputConfig.vue'
import MultipleSelect from './config/MultipleSelectConfig.vue'
import DateTime from './config/DateTimeConfig.vue'
import DateTimeRange from './config/DateTimeRangeConfig.vue'
import FileUpload from './config/FileUploadConfig.vue'
import Address from './config/AddressConfig.vue'
import Remote from './config/RemoteConfig.vue'
import SealInfo from './config/SealInfoConfig.vue'
import TakeOut from './config/TakeOutConfig.vue'

export default {
  //基础组件
  TextInput,
  TextareaInput,
  NumberInput,
  AmountInput,
  SelectInput,
  MultipleSelect,
  DateTime,
  DateTimeRange,
  FileUpload,
  Address,
  Remote,
  SealInfo,
  TakeOut
}
